import React from 'react';

const Loading = () => {
  return (
    <div className='container-loading'>
      <div className='loadingio-spinner-spin-vma6v5xnsvc'>
        <div className='ldio-uc4r6rt5dlf'>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
          <div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
